import { Fragment, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { classNames } from '~/lib/util';


export default function NetworkSelector() {

	function onSelected() {

	}
   
   return (
      <div className="hidden lg:block w-15 lg:w-40 flex-none mr-3">
         <Listbox onChange={onSelected}>
            {({ open }) => (
               <>
                  <div className="relative">
                     <Listbox.Button className="relative w-full text-white hover:text-primary-100 rounded-md pr-10 py-2 text-left focus:outline-3 focus:ring-1 focus:ring-primary-200 focus:border-primary-300 sm:text-sm">
                        <span className="flex items-center">
                           <img src="/assets/img/networks/radix.svg" alt="" className="flex-shrink-0 h-6 w-6 rounded-full" />
                           <span className="ml-3 text-base font-medium hidden lg:block">Radix</span>
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                           <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                     </Listbox.Button>

                     <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                           <Listbox.Option key="ethereum" value="ethereum" className="text-black hover:text-primary-500 hover:bg-neutral-100 relative cursor-default select-none py-2 pl-3 pr-9">
                              {({ selected }) => (
                                 <>
                                    <a href="https://app.defiplaza.net" className="flex items-center">
                                       <img src="/assets/img/networks/ethereum.svg" alt="" className="h-6 w-6 flex-shrink-0 rounded-full" />
                                       <div className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}>
                                          <p className="title text-base font-semibold hidden lg:block">Ethereum</p>
                                       </div>
                                    </a>
                                 </>
                              )}
                           </Listbox.Option>
                        </Listbox.Options>
                     </Transition>
                  </div>
               </>
            )}
         </Listbox>
      </div>
   );
}
